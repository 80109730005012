<template>
  <div>
    <CHeader light class="border-0 c-sticky-top">
      <nav
        class="navbar navbar-expand-lg navbar-light"
        style="padding-left: 11%; width: 100%"
      >
        <CRow class="login-header">
          <CIcon
            class="c-sidebar-brand-full mb-3"
            name="logo"
            size="custom-size"
            :height="45"
            viewBox="0 0 550 148"
            style="padding-right: 100px"
          />
        </CRow>
      </nav>
    </CHeader>
    <div class="signup-main">
      <CContainer class="container-form">
        <div class="left">
          <h1 class="section-title">
            {{ $t("RESET_PASSWORD.RESET_PASSWORD") }}
          </h1>
          <p>
            {{ $t("RESET_PASSWORD.MESSAGE") }}
          </p>
          <br />
          <ResetPasswordSVG />
        </div>
        <div class="right">
          <form class="form">
            <div class="fullrow">
              <CInput
                :label="$t('RESET_PASSWORD.ENTER_PASSWORD')"
                type="password"
                :placeholder="$t('RESET_PASSWORD.ENTER_PASSWORD')"
                class="col-12 p-0"
                v-model="newPassword"
                required
                valid-feedback
                :invalid-feedback="$t('SIGN_UP.VALIDATION.PASSWORD')"
                :is-valid="validatePassword"
              >
              </CInput>
            </div>
            <div class="fullrow">
              <CInput
                :label="$t('RESET_PASSWORD.CONFIRM_PASSWORD')"
                type="password"
                :placeholder="$t('RESET_PASSWORD.CONFIRM_PASSWORD')"
                class="col-12 p-0"
                v-model="confirmNewPassword"
                required
                :invalid-feedback="$t('RESET_PASSWORD.UNMATCH')"
                :valid-feedback="$t('RESET_PASSWORD.MATCHED')"
                :is-valid="validatePassword && confirmPasswords"
                :disabled="!validatePassword()"
              >
              </CInput>
            </div>
            <div class="rowcta mt-3">
              <CButton
                :disabled="!isActive"
                color="durianprimary"
                class="col-6 col-xl-6 px-4 mb-3 bigbutton"
                @click="resetPassword"
              >
                {{ $t("RESET_PASSWORD.CHANGE_PASSWORD") }}
              </CButton>
            </div>
          </form>
        </div>
      </CContainer>
    </div>
  </div>
</template>

<script>
import { ResetPassword } from "@/api/resetPassword.api.js";
import ResetPasswordSVG from "./ResetPasswordSVG";

export default {
  name: "ResetPassword",
  components: {
    ResetPasswordSVG,
  },
  data() {
    return {
      isActive: true,
      newPassword: null,
      confirmNewPassword: null,
      resetPasswordToken: null,
    };
  },
  methods: {
    confirmPasswords() {
      const { newPassword, confirmNewPassword } = this;
      return confirmNewPassword && newPassword === confirmNewPassword;
    },
    async resetPassword() {
      const { resetPasswordToken, newPassword, confirmNewPassword } = this;
      if (!newPassword || newPassword.length === 0) {
        this.showToaster(this.$t("RESET_PASSWORD.ERROR.BLANK"));
        return;
      }
      if (!confirmNewPassword || confirmNewPassword.length === 0) {
        this.showToaster(
          this.$t("RESET_PASSWORD.ERROR.CONFIRM_PASSWORD_BLANK")
        );
        return;
      }
      if (!resetPasswordToken) {
        this.showToaster(this.$t("RESET_PASSWORD.ERROR.TOKEN_BLANK"));
        return;
      }

      try {
        this.isActive = false;
        const response = await ResetPassword(
          resetPasswordToken,
          newPassword,
          confirmNewPassword
        );
        const err = this.verifyHttpResponse(response);
        if (err === false) {
          this.showSuccessToaster(this.$t("RESET_PASSWORD.RESET_SUCCESS"));
          this.$router.push("/");
        } else {
          this.isActive = true;
          this.showToaster(this.$t("RESET_PASSWORD.RESET_FAILURE"));
        }
      } catch (error) {
        this.isActive = true;
        this.showToaster(this.$t("RESET_PASSWORD.RESET_FAILURE"));
      }
    },
    validatePassword() {
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
      return this.newPassword && passwordPattern.test(this.newPassword);
    },
  },
  mounted() {
    this.resetPasswordToken = this.$route.query.token;
  },
};
</script>